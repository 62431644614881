import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import Grid from "../../components/grid"

import * as styles from "./blog.module.css"

function Posts({ posts }) {
  if (posts.length === 0) {
    return (
      <span style={{ gridColumn: "5 / 7", textAlign: "center" }}>
        Ainda não há posts
      </span>
    )
  } else {
    return (
      <ol className={styles.posts_container}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          return (
            <li key={post.fields.slug} className={styles.post}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2 className={styles.title}>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  {post.frontmatter.cover && (
                    <div className={styles.cover}>
                      <img
                        src={post.frontmatter.cover}
                        className={styles.cover_img}
                        alt={title}
                      />
                    </div>
                  )}
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          )
        })}
      </ol>
    )
  }
}

function Blog({ data, location }) {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} headerHome={false}>
      <Grid>
        <h1 className={styles.page_title}>NullBug Blog</h1>
        <Posts posts={posts} />
      </Grid>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD/MM/YYYY")
          title
          description
        }
      }
    }
  }
`
